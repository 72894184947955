<template>
  <el-card class="demand-card">
    <el-form slot="header" class="searchFrom" size="small"
             :inline="true"
             lable-width="80px" :model="searchForm">
      <el-form-item label="需求类型:">
        <el-select v-model="searchForm.reqflag" placeholder="全部">
          <template v-for="type in [{reqname: '全部', type: null, id: 0}].concat(condition.types)">
            <el-option :key="type.id" :label="type.reqname" :value="type.type"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="需求编号:">
        <el-input v-model="searchForm.requireId" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="提交时间:">
        <el-date-picker
          v-model="searchForm.createTimeLimit"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="审核状态:">
        <el-select v-model="searchForm.auditFlag" placeholder="全部">
          <template v-for="status in auditStatus">
            <el-option :key="status.value" :label="status.name" :value="status.value"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-button size="small" @click="getDemand" type="primary">搜索</el-button>
      <el-button @click="goPage('/submit-requirement')" type="primary" size="small"
                 icon="el-icon-plus">
        发布需求
      </el-button>
    </el-form>
    <el-row class="sctp-bg-f8 sctp-pad-tb10">
      <el-col :span="1">
        <div class="sctp-tc">
          <el-checkbox @change="setAllStatus" :indeterminate="indeterminate"
                       :value="selectAll"></el-checkbox>
        </div>
      </el-col>
      <el-col :span="11">
        <div class="sctp-tc">标题</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">预算</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">浏览数</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">查看量</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">上架状态</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">审核状态</div>
      </el-col>
      <el-col :span="2">
        <div class="sctp-tc">操作</div>
      </el-col>
    </el-row>
    <div v-loading="isLoadData" style="min-height: 100px;">
      <template v-if="demandData && demandData.length > 0">
        <template v-for="(demand,index) in demandData">
          <div :key="demand.requireId" class="demand-item">
            <div class="flex flex-sb sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-f8 sctp-info">
              <div class="sctp-flex">
                <div style="margin-right: 30px;">需求编号：{{ demand.requireId }}</div>
                <div style="margin-right: 30px;">提交时间：{{ dateToString(demand.createTime) }}</div>
                <div style="margin-right: 30px;">截至时间：{{ dateToString(demand.expectDate) }}</div>
                <div style="margin-right: 30px;">上架截至时间：{{ demand.deadline }}</div>
              </div>
              <div>
                <template v-if="demand.views">
                  <el-tooltip effect="dark" content="已被查看无法被删除" placement="top">
                    <a><i class="el-icon-delete fz-14"></i></a>
                  </el-tooltip>
                </template>
                <template v-else>
                  <a title="删除该需求" @click="deleteRequire(demand)"><i
                    class="el-icon-delete fz-14"></i></a>
                </template>
              </div>
            </div>
            <el-row type="flex" align="middle" class="sctp-pad-tb15">
              <el-col :span="1">
                <div class="sctp-tc">
                  <el-checkbox v-model="demand.selected"></el-checkbox>
                </div>
              </el-col>
              <el-col :span="11">
                <div >
                  <h4 class="sctp-ellipsis fz-16">
                    <a target="_blank"
                       :href="'/requirement/view/' + demand.requireId">{{ demand.title }}</a>
                    </h4>
                  <div class="sctp-ellipsis sctp-font-12 sctp-info sctp-mar-tb5">
                    <a target="_blank"
                       :href="'/requirement/view/' + demand.requireId">{{ demand.reqDesc }}</a>
                  </div>
                  <div class="">{{ demand.reqTypeName }} + {{ demand.typeName }}</div>
                  <div class="mg-t5">
                    <span v-if="demand.phone">联系电话：{{ demand.phone }}</span>
                    <span class="mg-l15" v-if="demand.contact">联系人：{{ demand.contact }}</span>
                  </div>
                  <div class="mg-t5 flex">
                    补充附件需求(仅支持PDF文件)：
                    <template v-if="demand.attachment">
                      <a target="_blank" :href="demand.attachment"
                         class="flex sctp-flex-aic">
                        <i class="el-icon-document mg-r5"></i>
                        {{ demand.attachmentName }}
                        <i @click.prevent="optionsConfig.onClick('removeAttachment', demand, index)"
                           class="el-icon-close mg-l10"></i>
                      </a>
                    </template>
                    <template v-else>
                      无
                      <el-upload
                        class="mg-l10"
                        title="点击上传附件"
                        :http-request="fileUploadFunction"
                        :data="{...uploadConfig.uploadEnum.DEMAND}"
                        :on-exceed="uploadConfig.fileExceed"
                        action=""
                        :disabled="demand.status === 1"
                        :show-file-list="false"
                        :before-upload="uploadConfig.beforeUpload.bind(null, {id: demand.requireId, item: demand})"
                        accept=".PDF"
                        :on-success="uploadConfig.uploadSuccess.bind(null,{id: demand.requireId})"
                        :on-remove="uploadConfig.onRemove.bind(null,{id: demand.requireId})">
                        <i @click.prevent="() => {
                      if(demand.status === 1) {
                        $alert('请先下架再上传附件!', '提示');
                      }
                    }" class="el-icon-document"></i>
                        <div class="el-upload__tip" slot="tip"></div>
                      </el-upload>
                    </template>
                  </div>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <div>{{ demand.budgetScope || '无预算' }}</div>
                  <i
                    @click="updatePriceConfig.onShowClick(demand)"
                    class="el-icon-edit"></i>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  {{ demand.hits || 0 }}
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <el-tooltip effect="dark" content="查看访问用户" placement="top">
                    <el-tag
                      size="small"
                      @click="optionsConfig.onClick('accessUser', demand, index)"
                      style="cursor: pointer;"
                    >
                      {{ demand.views || 0 }}
                    </el-tag>
                  </el-tooltip>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <template v-if="demand.auditFlag === 1">
                    <el-tag size="small" effect="dark" v-if="demand.status === 1">
                      已上架
                    </el-tag>
                    <el-tag size="small" type="info" effect="dark" v-else-if="demand.status === 0">
                      未上架
                    </el-tag>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </el-col>
              <el-col :span="2">
                <div class="sctp-tc">
                  <template v-if="demand.auditFlag || demand.auditFlag === 0">
                    <el-tooltip
                      effect="dark"
                      :disabled="demand.auditFlag !== 2"
                      :content="demand.auditMemo" placement="top"
                    >
                      <el-tag
                        style="cursor: pointer;"
                        size="mini" :type="auditStatusTag[demand.auditFlag].type"
                      >
                        {{ auditStatusTag[demand.auditFlag].name }}
                      </el-tag>
                    </el-tooltip>
                  </template>
                  <el-tooltip class="item" effect="dark" content="点击查看审核历史" placement="top">
                    <a
                      @click="optionsConfig.onClick('history', demand, index)"
                    ><i class="el-icon-question"></i></a>
                  </el-tooltip>
                </div>
              </el-col>
              <el-col :span="2">
                <template v-if="demand.temporaryStorage">
                  <div class="sctp-mar-b5 sctp-tc">
                    <el-button
                      size="mini"
                      @click="goPage(`/submit-requirement/${demand.requireId}`)"
                    >
                      继续完善
                    </el-button>

                  </div>
                </template>
                <template v-else>
                  <template v-if="demand.auditFlag === 1">
                    <template v-if="demand.status === 1">
                      <div class="sctp-mar-b5 sctp-tc">
                        <el-button
                          size="mini"
                          @click="optionsConfig.onClick('down', demand, index)"
                        >
                          下架
                        </el-button>
                      </div>
                    </template>
                    <template v-else>
                      <div class="sctp-mar-b5 sctp-tc">
                        <el-button
                          size="mini"
                          @click="optionsConfig.onClick('up', demand, index)"
                        >
                          上架
                        </el-button>
                      </div>
                    </template>
                  </template>
                  <div class="sctp-mar-b5 sctp-tc">
                    <el-button
                      size="mini" type="danger"
                      @click="optionsConfig.onClick('edit', demand, index)"
                    >
                      编辑
                    </el-button>
                  </div>
                </template>
              </el-col>
            </el-row>
            <template v-if="demand.recommendProducts && demand.recommendProducts.length > 0">
              <div
                style="max-height: 100px;overflow: auto;padding: 10px 0 10px 80px;border-top: 1px solid #eee;">
                <div>用户推荐</div>
                <template v-for="(recommendItem,recommendIndex) in demand.recommendProducts">
                  <el-row type="flex" align="middle" class="sctp-pad-tb5">
                    <el-col :span="11">
                      <div>{{ recommendIndex + 1 }}、{{ recommendItem.productName }}</div>
                    </el-col>
                    <el-col :span="2">
                      <div>{{ recommendItem.storeName }}</div>
                    </el-col>
                    <el-col :span="2">
                      <div>{{ dateToString(recommendItem.recommendDate, 'yyyy-MM-dd') }}</div>
                    </el-col>
                    <el-col :span="2">
                      <el-button @click="openPage({
                  SOURCECODE: `/sourcecode/view/${recommendItem.productId}`,
                  DESIGN: `/design/view/${recommendItem.productId}`,
                  DOCUMENT: `/techdocs/view/${recommendItem.productId}`,
                  }[recommendItem.type])" size="mini">详情
                      </el-button>
                    </el-col>
                  </el-row>
                </template>
              </div>
            </template>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </div>
    <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
      <el-pagination
        :current-page="pageConfig.page"
        :page-size="pageConfig.limit"
        @size-change="pageConfig.handleSizeChange"
        @current-change="pageConfig.handleCurrentChange"
        :page-sizes="pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="pageConfig.total">
      </el-pagination>
    </div>
    <el-dialog title="访问用户" :visible.sync="showAccessUser">
      <access-user type="DEMAND" :id="activeRequireId"></access-user>
    </el-dialog>
    <el-dialog title="审核历史" class="auditHistory" :visible.sync="showAuditHistory">
      <audit-history type="DEMAND" :id="activeRequireId"></audit-history>
    </el-dialog>
    <el-dialog width="600px" center title="本需求发布需支付服务费" :visible.sync="payDialogConfig.showFlag">
      <pay-dialog type="DEMAND" @pay="payDialogConfig.onPay"></pay-dialog>
    </el-dialog>
    <el-dialog width="400px" center title="修改预算" :visible.sync="updatePriceConfig.show">
      <el-form :model="updatePriceConfig.data" :rules="updatePriceConfig.rules"
               :ref="updatePriceConfig.formName">
        <el-form-item class="w50" label="预算金额(选填)" prop="budgetScope">
          <el-select v-model="updatePriceConfig.data.budgetScope" placeholder="请选择">
            <el-option
              v-for="item in condition.budgets"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
          元
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary"
                     @click="updatePriceConfig.onSubmitClick">提交修改
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import {demand, goods} from "@/apis/index";
import uploadEnum from "@/resources/js/uploadenum";

export default {
  name: 'demand',
  components: {
    AccessUser: () => import('@PAGE/users-center-manage/parttime-manage/AccessUserHistory.vue'),
    AuditHistory: () => import('@PAGE/users-center-manage/AuditHistory.vue'),
    payDialog: () => import('@PAGE/users-center-manage/requirement-manage/RequirementOnlinePay'),
  },
  data() {
    return {
      isLoadData: false,
      condition: {
        types: [],
        budgets: [],
      },
      updatePriceConfig: {
        formName: 'updatePriceDialogFormName',
        show: false,
        data: {
          budgetScope: null,
        },
        rules: {
          budgetScope: [
            {required: true, message: '请选择预算金额', trigger: 'change'},
          ],
        },
        onShowClick: (item) => {
          this.activeRequireId = item.requireId;
          this.updatePriceConfig.show = true;
        },
        onSubmitClick: () => {
          this.$refs[this.updatePriceConfig.formName].validate()
          .then(() => {
            let {budgetScope} = this.updatePriceConfig.data;
            let minBudget, maxBudget;
            minBudget = budgetScope.split('-')[0];
            maxBudget = budgetScope.split('-')[1];
            goods.updateDemandPrice({
              id: this.activeRequireId,
              budgetScope,
              minBudget: minBudget,
              maxBudget: maxBudget,
            }).then(() => {
              this.updatePriceConfig.show = false;
              this.$message.success('保存成功！');
              this.getDemand();
            })
          });
        },
      },
      searchForm: {
        reqflag: null,
        requireId: null,
        createTimeLimit: null,
        auditFlag: null,
      },
      auditStatusTag: [
        {name: '待审核', type: 'info'},
        {name: '已通过', type: 'success'},
        {name: '未通过', type: 'danger'},
        {name: '强制下架', type: 'danger'},
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      demandData: [],
      activeRequireId: null,
      showAccessUser: false,
      showAuditHistory: false,
      pageConfig: {
        page: 1,
        limit: 10,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.getDemand()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getDemand()
        }
      },
      payDialogConfig: {
        showFlag: false,
        showUpperShelvesDialog: () => {
          this.payDialogConfig.showFlag = true
        },
        onPublishPaySuccess(id) {
          this.payDialogConfig.showFlag = false;
          this.doChangeStatus(1, id)
        },
        onPay: ({payWay, price, date}) => {
          return this.$request.post({
            reqcode: '1121',
            reqdesc: '需求上架',
            payWay: payWay,
            date: date,
            requireId: this.activeRequireId,
            userId: this.user.userId
          }).then(res => {
            this.payDialogConfig.showFlag = false;
            this.$message.success('操作成功');
            this.getDemand()
          })
        }
      },
      optionsConfig: {
        options: {
          accessUser: ({item, index}) => {
            this.showAccessUser = true
          },
          removeAttachment: ({item, index}) => {
            // 移除附件
            this.$confirm('确认移除附件?', '提示', {
              type: 'warning'
            }).then(() => {
              demand.removeAttachment({id: item.requireId})
              .then(res => {
                this.$message.success('移除成功');
                this.getDemand();
              })
            })
          },
          history: ({item, index}) => {
            this.showAuditHistory = true
          },
          up: ({item, index}) => {
            this.doChangeStatus(1, item.requireId).then(() => {
              this.$message.success('上架成功');
            });
          },
          down: ({item, index}) => {
            this.$confirm('还未到截至时间,确定要下架吗?', '提示', {
              type: 'warning'
            }).then(() => {
              this.doChangeStatus(0, item.requireId).then(() => {
                this.$message.success('下架成功');
              });
            })
          },
          edit: ({item, index}) => {
            let editPage = () => {
              this.goPage({
                path: `/submit-requirement/${item.requireId}`
              })
            };
            if (item.views && item.views > 0) {
              this.$alert('已有用户查看，无法编辑', '提示');
              return;
            }
            // if (item.status === 1) {
            //   this.$confirm('编辑需要需要先下架，确定下架吗？', '提示', {
            //     type: 'warning'
            //   }).then(() => {
            //     this.doChangeStatus(0, item.requireId)
            //     .then(() => {
            //       editPage();
            //     });
            //   });
            //   return;
            // }
            // 审核通过的提醒会下架   上架的需要下架
            if (item.auditFlag === 1) {
              this.$confirm(`${item.status === 1 ? '编辑需要先下架，' : ''}重新编辑后需要重新审核，确定编辑吗？`, '提示', {
                type: 'warning'
              }).then(() => {
                if (item.status === 1) {
                  this.doChangeStatus(0, item.requireId)
                  .then(() => {
                    editPage();
                  });
                } else {
                  editPage();
                }
              });
              return;
            }
            editPage();
          },
        },
        onClick: (option, item, index) => {
          this.activeRequireId = item.requireId;
          if (this.optionsConfig.options[option]) {
            this.optionsConfig.options[option].call(null, {item: item, index: index});
          }
        }
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({id}, response, file, fileList) => {
          demand.addAttachment({
            id, path: response.src
          }).then(() => {
            this.$message.success('文件上传成功！');
            this.getDemand();
          })
        },
        onRemove: ({id}, file, fileList) => {
        },
        beforeUpload: ({id}, file) => {
          let result = false;
          const isMatchType = 'application/pdf' === file.type;
          const isLtSize = file.size / 1024 / 1024 < 1;
          if (!isMatchType) {
            this.$message.error('只支持PDF文件!');
            return false;
          }
          if (!isLtSize) {
            this.$message.error(`上传文件大小不能超过 1MB!`);
            return false;
          }
          result = isMatchType && isLtSize;
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      }
    }
  },
  methods: {
    loadCondition() {
      demand.condition().then(res => {
        const {data = [], types = [], budgets = []} = res;
        if (res) {
          Object.keys(res).forEach(key => {
            this.condition[key] = res[key];
          });
        }
      })
    },
    getDemand() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      if (searchForm.createTimeLimit) {
        searchForm.createStart = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          'yyyy-MM-dd 00:00:00');
        searchForm.createEnd = this.dateToString(new Date(searchForm.createTimeLimit[1]),
          'yyyy-MM-dd 23:59:59');
        delete searchForm.createTimeLimit
      }
      this.isLoadData = true;
      const {pageConfig: {page, limit}} = this;
      return demand.userCodeRequireList({
        userId: this.user.userId,
        createStart: searchForm.createStart,
        createEnd: searchForm.createEnd,
        requireId: searchForm.requireId,
        reqFlag: searchForm.reqflag,
        auditFlag: searchForm.auditFlag,
        page: page,
        limit: limit
      }).then(res => {
        const {data = [], count = 0} = res;
        data.forEach(item => {
          if (item.shelvesDeadline) {
            item.deadline = this.dateToString(new Date(item.shelvesDeadline));
            if (item.shelvesDeadline < new Date().getTime()) {
              item.deadline = item.deadline + '(已过期)'
            }
          } else {
            item.deadline = '未上架'
          }
          item.selected = false
        });
        this.demandData = data;
        this.pageConfig.total = count
      }).finally(() => {
        this.isLoadData = false;
      })
    },
    onChangeStatus(status, demand) {
      this.activeRequireId = demand.requireId;
      if (status === 1) {
        demand.status = 0;
        this.payDialogConfig.showFlag = true
      } else {
        this.doChangeStatus(status, demand.requireId)
      }
    },
    doChangeStatus(status, requireId) {
      return this.$request.post({
        reqcode: '1058',
        reqdesc: '修改需求状态',
        userId: this.user.userId,
        requireId: requireId,
        status: status
      }).then(res => {
        this.getDemand()
      }).catch(res => {
        if (res && res.retCode === '9002') {
          this.payDialogConfig.showFlag = true;
        }
        return Promise.reject(res);
      });
    },
    deleteRequire(require) {
     if (require.status===1){
       this.$message.warning("原创作品已发布上架，请先下架再删除!");
       return;
     }
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        if (require.status === 1){
          this.$message.warning('请先进行下架');
          return;
        }
        this.$request.post({
          reqcode: '1059',
          reqdesc: '删除需求',
          requireId: require.requireId,
          userId: this.user.userId
        }).then(res => {
          this.$message.success('删除成功');
          this.getDemand()
        })
      })
    },
    setAllStatus(flag) {
      if (this.demandData) {
        this.demandData.forEach(demand => {
          demand.selected = !!flag
        })
      }
    },
  },
  computed: {
    selectAll() {
      let flag = false;
      if (this.demandData && this.demandData.length > 0) {
        flag = this.demandData.every(demand => {
          return demand.selected
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        let flag = false;
        if (this.demandData) {
          flag = this.demandData.some(demand => {
            return demand.selected
          })
        }
        return flag
      }
    },
    auditStatus() {
      return [
        {name: '全部', value: null},
        {name: '待审核', value: 0},
        {name: '已通过', value: 1},
        {name: '未通过', value: 2}
      ]
    }
  },
  beforeMount() {
    this.loadCondition();
    this.getDemand();
  }
}
</script>

<style scoped lang="scss">
.demand-card {
  /deep/ {
    .el-input {
      width: 110px !important;
    }

    .el-card__body {
      padding: 12px;
    }

    .el-dialog {
      width: 80%;
    }

    .el-dialog__body {
      overflow-y: auto;
    }

    .demand-item {
      border: 1px solid #e5e5e5;
      margin-top: 15px;
      vertical-align: middle;

      .el-icon-edit {
        display: none;
        cursor: pointer;
      }

      .el-col:hover .el-icon-edit {
        display: inline-block;
      }
    }

  }
}

.auditHistory /deep/ .el-dialog__body {
  padding-top: 0;
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 280px;
}

</style>
